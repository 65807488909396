var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Stack, TextField, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// translation
import { useLocales } from '../../locales';
export default function RHFDate(_a) {
    var name = _a.name, _b = _a.inputFormat, inputFormat = _b === void 0 ? undefined : _b, helperText = _a.helperText, multiline = _a.multiline, rows = _a.rows, type = _a.type, inputRef = _a.inputRef, id = _a.id, label = _a.label, isDisabled = _a.isDisabled, views = _a.views, minDate = _a.minDate, maxDate = _a.maxDate, other = __rest(_a, ["name", "inputFormat", "helperText", "multiline", "rows", "type", "inputRef", "id", "label", "isDisabled", "views", "minDate", "maxDate"]);
    var _c = useFormContext(), control = _c.control, setValue = _c.setValue;
    var translate = useLocales().translate;
    var today = new Date();
    today.setHours(0, 0, 0);
    return (_jsx(Controller, { name: name, control: control, render: function (_a) {
            var field = _a.field, error = _a.fieldState.error;
            return (_jsxs(Stack, __assign({ sx: { display: 'flex', flexDirection: 'column' } }, { children: [_jsx(Tooltip, __assign({ title: "".concat(translate(helperText)) || "".concat(translate(label)) }, { children: _jsx(DatePicker, { views: views, label: "".concat(translate(label), " "), disabled: isDisabled, inputFormat: inputFormat !== null && inputFormat !== void 0 ? inputFormat : 'dd MM yyyy', value: field.value ? new Date(field === null || field === void 0 ? void 0 : field.value) : null, minDate: minDate, maxDate: maxDate, onOpen: function () {
                                setValue(name, field.value ? new Date(field === null || field === void 0 ? void 0 : field.value) : new Date(today));
                            }, renderInput: function (props) { return _jsx(TextField, __assign({}, props)); }, onChange: function (newValue) {
                                field.onChange(newValue);
                            } }) })), (!!error || helperText) && (_jsx(FormHelperText, __assign({ error: !!error }, { children: error ? "".concat(translate(error === null || error === void 0 ? void 0 : error.message)) : "".concat(translate(helperText)) })))] })));
        } }));
}
